/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
.f1-400 {
    font-size: 1rem;
    font-weight: 400;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
body {
    background-color: #eeeeee
}
.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:1s;
    -moz-animation-duration:1s;
    animation-duration:1s;
}

.fadeIn.first {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.fadeIn.second {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.fadeIn.third {
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

.fadeIn.fourth {
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-delay: 1s;
}

.logo{
    width: 200px;
    margin-bottom: 20px;
}
.login{
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
}
.loginBtn{
    padding: 10px 40px 10px 40px !important;
    color: #fff;
    background-color: #198754;
    border-color: #198753;
}
.loginBtn:hover{
    padding: 10px 40px 10px 40px !important;
    color: #fff !important;
    background-color: #198754 !important;
    border-color: #198753 !important;
}
.border-bottom-red{
    border-bottom: 3px solid red !important;
    border-radius: 0 !important;
}
.pagination{
    float: right;
}
.nav-link{
    transition: none;
}
.btn-circle {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 0 0 3px;
    font-size: 16px;
    /* background-color: #5db461; */
    border: 0;
    color:#FFFFFF;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 992px) {
    .dashboard,
    .home,
    .news,
    .news-list,
    .add-news,
    .edit-news,
    .category,
    .category-list,
    .add-category,
    .edit-category,
    .user,
    .add-user,
    .edit-user,
    .user-list,
    .tag {
        /*height: 90vh;*/
        position: fixed;
        justify-content: center;
        margin-left: 260px;
        padding-right: 300px;
        top: 20px;
        left: 10px;
        bottom: 10px;
        overflow-y: scroll;
    }
    .icon {
        border-Radius: 2px;
        margin-top: -40px;
        margin-bottom: 50px;
    }
}
@media (min-width: 240px) and (max-width: 992px) {
    .dashboard,
    .home,
    .news,
    .news-list,
    .add-news,
    .edit-news,
    .category,
    .category-list,
    .add-category,
    .edit-category,
    .user,
    .add-user,
    .edit-user,
    .user-list,
    .tag {
        height: 90vh;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        padding-right: 0;
    }

    .icon {
        border-Radius: 2px;
        margin-top: -40px;
        margin-bottom: 30px;
    }
    .input-outline-border {
        outline: 0 !important;
        border-width: 0 0 1px !important;
        border-radius: 0 !important;
    }
    .login{
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

}

.tag_list {
    font-size: 15px;
    text-align: center;
    padding: 9px 15px;
    border: 1px solid #E4E5E6;
    background: #E4E5E6;
}
.author{
    position: relative;
    z-index: 2;
    padding-left: 60px;
    display: inline-block;
    text-align: left;
}
.author .author_img{
    left: 0;
    position: absolute;
    top: 50%;
    height: 50px;
    width: 50px;
    margin-top: -25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border: 1px solid #e4e5e6;
    border-radius: 50%;
    overflow: hidden;
}
.author_img_wrap img,
.comment_img img {
  border-radius: 50%;
}
.author ul li {
    list-style: none;
    display: inline-block;
    color: #73797f;
    font-size: 15px;
}
.author a {
    color: #17222b;
    font-weight: 500;
    font-size: 14px;
}
a {
    display: inline-block;
    text-decoration: none;
}
.author ul li a {
    display: inline-block;
    color: #73797f;
    font-weight: 400;
    font-size: 15px;
}
