.logo{
    margin-top: 25px;
}
ul{
    //list-style-type:none;
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0;
}
li {
    text-decoration: none;
    align-items: center;
    margin-top: 0;
    list-style: none;
}
.nav{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-decoration: none;
}

.nav-link{
    display: grid;
    color: #e1e9fc;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    text-decoration: none;
    font-size: 18px;
    padding: 10px 50px 0 20px;
    height: 70px;


}
.nav-link:hover{
    background: #2d4250;
    border-left: 8px solid #632ce4;
    color: #e1e9fc;
    cursor: pointer;
    padding: 20px 110px 0 10px !important;
    /*padding-top: 20px;*/
    /*padding-right: 50px;*/
    /*padding-bottom: 0;*/
    /*padding-left: 10px;*/
    margin-bottom: 20px;
    justify-content: flex-start;
    display: grid;


}
.nav-link:active{
    background: #2d4250 !important;
    color: #e1e9fc !important;
    cursor: pointer;
    text-decoration: none;
    //padding: 0 173px 0 0 !important;
}

/*.nav-dropdown-link{*/
/*    background: #414757;*/
/*    display: flex;*/
/*    color: #e1e9fc;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    list-style: none;*/
/*    text-decoration: none;*/
/*    font-size: 18px;*/
/*    height: 60px;*/
/*}*/
/*.nav-dropdown-link :hover{*/
/*     background: #7952b3;*/
/*     color: #e1e9fc;*/
/*     cursor: pointer;*/

/* }*/


/*.nav {
    background: #15171c;
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.nav-icon-bar {
    margin-left: 1.4rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
}
.nav-icon-close {
    margin-left: 12.5rem;
    margin-top:1.5rem;
    font-size: 2rem;
    height: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.sidebar-nav {
    background: #15171c;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 10;
}
.sidebar-nav.active {
    left: 0;
    transition: 350ms;
    }
.sidebar-warp {
    width: 100%;
}

.nav-title {
    display: flex;
    color: #e1e9fc;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    text-decoration: none;
    font-size: 18px;
    height: 60px;
}

.nav-title a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.nav-title a:hover {
    background: #252831;
    border-left: 5px solid #632ce4;
}

.logo-title {
    color: #f5f5f5;
    text-align: center;
}
ul {
    padding-left: 0rem;
}
span {
    margin-left: 20px;
}*/

/*a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}*/

/*.sidebar {
   background: #15171c;
    width: 80px;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
}
.sidebar.active {
    left: 0;
    transition: 350ms;
}
.side-bars {
    margin-left: 1.5rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    background: none;
    text-decoration: none;
}
.iconClose{
    float:right;
}

.side-menu {
    background-color: #281647;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 1;
}

.side-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}


.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-radius: 4px;
}


.nav-text a:hover {
    background: #1a83ff;
    text-decoration: none;
}

.sidebar-items {
    width: 100%;
}*/

/*.sidebar-toggol {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;

}
span {
    margin-left: 16px;
}
.logo {
    color: #f5f5f5;
    text-align:center;
}
ul{
    padding-left:0rem;
}
*/

